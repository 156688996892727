import styles from './App.module.css';
import MainScreen from './screens/MainScreen';

function App() {
  return (
    <MainScreen/>
  );
}

export default App;
