import { Link } from "react-router-dom";
import styles from "../screens.module.css";

export default function ReflectionScreen2021(props) {
    return(
        <div className={styles.container}>
            <p className={styles.title}>
                Posts
            </p>
            <p className={styles.articleTitle}>
                A Year in Review -- 2021
            </p>
            <p className={styles.articleSubtitle}>
                2022-01-02
            </p>
            <blockquote>“One, remember to look up at the stars and not down at your feet. Two, never give up work. Work gives you meaning and purpose and life is empty without it. Three, if you are lucky enough to find love, remember it is there and don't throw it away.” 
            <br/>- Dr. Stephen Hawking</blockquote>
            <p>Anthropologists define liminality as the ambiguity that lies between the statuses before and after a life-altering ritual. It exists both in the realms of space and time, as most things do; the hazy period lingering in the void between one state of being and the next. It describes a graduation ceremony; a career change; a move; a pandemic. It encapsulates the key theme of the last 584 million miles ranged by the planet earth in its unceasing journey around the sun.</p>
            <p>I wrote a reflection at the end of 2020 to wrap up what was (at the time) the most critical year in my life; this writeup is a one-time sequel in some ways and a many-time prequel in others. Last year’s reflection was born of my transition into the startup world, a world which at the time I knew little about. The optimism I wrote that piece with reflects how little I knew; the unbridled optimism of barely-21-year-old me is now met with some of the more achievable (if less impressive-sounding) realism of barely-22-year-old me.</p>
            <p>This year came with its own challenges and opportunities; it’s one that looks fantastic on paper. I graduated from a top US school, I have a great job, great friends, a great family. I’m fortunate enough to have largely avoided the brutality of the COVID-19 pandemic; I’m in a position in life that at least 7 billion individuals on planet earth would kill to be in. Yet the sheer mass of possibilities in front of me — what to build, how to live, how to think, how to act — sprawl infinitely into the future, in a maze I have no idea how to navigate.</p>
            <p>I finished off my coursework at UC Berkeley with three courses — complex analysis,  differential geometry, and ethics in Silicon Valley. I dropped my 4th course of statistical thermodynamics after 3 weeks, since I didn’t know anyone in the class, nor did I know any*thing* in the class. I took a ton of <a href="http://kany.es/notes">notes</a>, created my first <a href="https://youtu.be/9xgli0GM29w">instructional video</a>; I walked across the stage of the Berkeley Greek Theater in May to accept my diploma with a double major in Computer Science and Mathematics.</p>
            <p>I co-created a <a href="https://chrome.google.com/webstore/detail/ferret/mjnmolplinickaigofdpejfgfoehnlbh">Chrome extension</a> with Sam, my brother from Burbank, that uses NLP to help you learn on the fly (which <a href="https://twitter.com/KanyesThaker/status/1431378692912025604?s=20">caught the attention</a> of the HuggingFace founders and is currently sitting at <a href="https://github.com/kanyesthaker/qgqa-flashcards">61 GitHub stars</a>). I got a job at <a href="https://snorkel.ai">Snorkel AI</a>, where I work on understanding how real people struggle with data and design intelligent systems to make high-quality ML accessible to all.</p>
            <p>And I spent two weeks in Greece with 8 of my most incredible friends. I moved into an apartment in Mountain View with Shalin, my brother from RSM, which may one day turn into our own Bandley 1. I crossed two years on this earth with Divya, my inspiring and beautiful girlfriend, who’s pushed me beyond my limitations into the person I am today. I grew closer to my family, to my sister and my parents; I strengthened my bond with old friends and forged new ones with others. Graduation brought with it some bittersweet partings — but the 11 months of quarantine softened the blow. The friends I made it past the pandemic with will certainly stay with me til the end of the line.</p>
            <h2>Learning</h2>
            I read less than I would have liked this year, clocking in at only 10 long-form books and under 20 long-form articles. Among my favorites:
            <ul>
                <li><em>A Brief History of Time</em>, by Stephen Hawking</li>
                <li><em>Zero to One</em>, by Peter Thiel</li>
                <li><em>Can't Stop Won't Stop: A History of the Hip-Hop Generation</em> by Jeff Chang</li>
                <li><em>The Moves that Matter</em> by Jonathan Rowson</li>
            </ul>
            <p>My current <a href="https://www.notion.so/69f47a3559ed4f03ba474f8787f60d07">library</a> includes 60 books, ranging from startup stories and management to art and philosophy; from great mathematicians to great freedom fighters. I’m amassing these almost entirely from recommendations from the people that know me best. I haven’t been all that diligent about reading this year — but I’m starting to forge a more disciplined set of habits around the content I consume, and I’m optimistic about my reading habits in the future.</p>
            <p>Along the way, I’m actively extending my education — over 2021, I began (but haven’t finished) self-studying advanced topics in machine learning, large-scale distributed systems, and quantum mechanics, all part of my multi-year post-college <a href="https://kanyes.notion.site/c2ba350f575840388de647f59f070c32?v=1586e1855395467990c90c1d8139fb55">study plan</a>. This year’s subjects include (but aren’t limited to) combinatorics, computer networks, and deep learning.</p>
            <p>I’ve also started to write. One of my goals at the dawn of 2021 was to hone the craft of writing, an invaluable skill that I’ve wanted to master for ages. I fell short of my ridiculously ambitious initial goal of writing one piece per week. I wrote two long-form essays: a <a href="https://kany.es/eecs">reflection</a> on my experience in Berkeley CS, and an as-yet unpublished <a href="https://kanyes.notion.site/ML-Metrics-Matter-456064b143d14fa5ba80e13aba40b842">technical overview</a> of ML evaluation techniques. I’ve struggled to find times to put my pen to the parchment this past year; perhaps it’s a sign of a lack of focus, or perhaps a sign of too much focus on something else. </p>
            <h2>Values</h2>
            <p>My personal values drive how I live my life. They determine my goals, my actions, my thoughts, and my feelings. Last year’s values are below:</p>
            <ul>
                <li>Don't settle</li>
                <li>Keep smiling</li>
                <li>Be afraid, then do it anyway</li>
                <li>Wherever you go, there you are</li>
                <li>Make decisions right</li>
                <li>Master of one over master of none</li>
                <li>Do great work</li>
                <li>Keep learning</li>
                <li>Stay grounded</li>
            </ul>
            <p>It would be erroneous to say that these values are outdated, yet also erroneous to say they are still entirely accurate. There are two issues with them that I’ve noticed this past year — they’re too ambiguously defined to measure and too undirected to follow. A colleague at Snorkel gave me a good phrase — <em>approximately north</em> — in that every decision made in any aspect of an organization (or individual) should point in the general direction of some ultimate truth, even if that truth is nothing more than a cardinal arrow.</p>
            <p>For me, that arrow stands as it did a year ago; the desire to do great, world-changing work; to solve pressing, crucial problems by building incredible products. To leave this world in a better state than when it started, and to place down my piece in the jigsaw puzzle that is human history. To me, the ultimate goal in my infinitesimal existence is to ask the hardest questions; to find the hardest answers — to build the future.</p>
            <p>A lot of my old values are framed with a feeling of avoidance or negativity; I’ve learned that learning what <em>not</em> to do is easy; learning what <em>to</em> do is much harder. Jonathan Rowson in <em>The Moves that Matter</em> explains that most people think of freedom as the freedom from something constraining; but it’s much more valuable to think of it as the freedom to achieve something incredible. These values are framed with this in mind.</p>
            <p>Values should be something that can be linked directly to goals, and measured at even intervals as they evolve. They should form a delicate expectation-maximization loop — I should be able to optimize my life to fit my values while optimizing my values to fit my life. Here are my values for 2022:</p>
            <ul>
                <li>Climb relentlessly</li>
                <li>Live gracefully</li>
                <li>Make decisions right</li>
                <li>Pursue true understanding</li>
                <li>Be nearsighted and farsighted</li>
                <li>Be now, do now</li>
            </ul>
            <h3>Climb Relentlessly</h3>
            <p>Life is short. I know I could never be satisfied with the average; with the idea of spending my working hours staring at the clock, punching in at 9:01 and logging off at 4:59. Watching TV for too long makes me antsy; eating at restaurants for a week makes my hands yearn for my chef’s knife. There’s always another mountain to climb and another mile to run. Whether it’s in the gym, at work, in my personal relationships, or in the kitchen, I strive to tower over the person I was a year ago and am envious of the person I will be a year from now. Not to get a paycheck or a raise; but because I can.</p>
            <blockquote>“Perfection is not attainable, but if we chase perfection, we can catch excellence.”<br/>
            ―Vince Lombardi at the first team meeting of the Green Bay Packers, 1959</blockquote>
            <h3>Live Gracefully</h3>
            <p>I refuse to live two lives; I don’t want to be one way in my work and another way with my friends and family. It’s easy to get lost in Silicon Valley — the “main character” syndrome that so many young folks in tech have, where they see themselves as the center of some Michael Fassbender’s <em>Steve Jobs</em> type fantasy. And some of that is necessary, since overconfidence is a more powerful tool than underconfidence. But I sense the massive danger of being consumed by the ego; of becoming the person who begins every day with meditation yet walks the streets of SoMA thinking the camera’s on them. I grew up as a musician, an artist; this is something I can’t lose. It keeps me grounded, it keeps me from taking things more seriously than they need to be taken.</p>
            <p>The pendulum also swings the other way. Many uneducated (and educated) people get trapped in consumerism. And lots of educated (and uneducated) people resort to nihlism, and proclaim the universe has no meaning, and so no problems are truly important. I think the key is somewhere in the middle — just because the leptons and hadrons that make up our cosmos may not have an inherent meaning, doesn’t mean they don’t have a meaning for me. This has mostly come up as I think about my salary here at my first job. The concept of money is completely intangible, and it’s bizarre to see these numbers flood my bank account every two weeks. Yet declaring that money has no meaning, or that we can live in a post-money society, is absurd — though it isn’t tangible, it is undeniably real, and it is absolutely necessary to move the world. Therefore it is worth pursuing to some capacity, even though the hadrons and leptons may disagree.</p>
            <p>This is what I mean by living gracefully: to be of the world, though not consumed by it. To temper the founder-fantasy of being the next Steve Jobs, to give my time to help feed the homeless and spend some sunsets at my piano instead of at my laptop. A more arrogant version of me would have said that these things distract from the pursuit of perfection. They don’t. Rather, they offer perspectives that enhance that pursuit; they bolster the meaning of a meaningless universe.</p>
            <blockquote>“...And medicine, law, business, engineering, these are noble pursuits and necessary to sustain life. But poetry, beauty, romance, love, these are what we stay alive for.”<br/>
            - John Keating (Robin Williams), Dead Poets Society</blockquote>
            <h3>Make Decisions Right</h3>
            <p>Life is an unsolved, finite game. Even if <a href="https://en.wikipedia.org/wiki/Laplace%27s_demon">Laplace's demon</a> exists on some distant astral plane, the uncertainty principle prohibits a definite future. So there’s no point in wasting time making decisions based purely on the outcomes of my actions; rather, I aim to perfect the practice, and let the score take care of itself. If I can live my life based on my values, and make decisions in the right way, then regardless of the outcome, the future is as much in my control as it could possibly be.</p>
            <blockquote>कर्मण्येवाधिकारस्ते मा फलेषु कदाचन
            <br/>Karmanye vadhikaraste Ma Phaleshu Kadachana
            <br/>"You have only the rights to do your duty; never on the fruits of your labor."
            <br/>-The Bhagavad Gita, 2:47</blockquote>
            <h3>Pursue True Understanding</h3>
            <p>This is a new one for me. As I continue to learn and continue to study, my knowledge sometimes feels surface level. I’ve noticed a dangerous pattern in my explanations when Shalin or Sam ask me to explain technical concepts, even ones that I think I’ve studied extensively. I follow this script: <em>“Have you heard of [X]? Ok, so [X] is <b>basically just</b> [Y]...”</em> In this example, X is some obscure or unnecessary element of technical jargon that isn’t really all that important in the concept I’m talking about. And Y is usually an explanation that’s at least partially inaccurate, or misses out on large portions of critical background, or would be called out 10 times if I said it to a professor. If mastery is a stepping stone to greatness, this attitude is unacceptable; I can’t use X as a façade to masquerade how poorly I can explain Y.</p>
            <p>A half-hearted understanding of a topic won’t get me anywhere. I have lots of time — I’m 22 years and 1 week old. Some of my friends can read novels and textbooks in hours and retain that information perfectly. I know I can’t, and that’s OK — I’m here to win the marathon, not the sprint. This might take twice as long; it might take 100 annoying questions and I’ll almost definitely look dumb along the way. But I don’t care — because I care more the pursuit of knowledge than the display of knowledge.</p>
            <blockquote>"Feynman was once asked by a Caltech faculty member to explain why spin 1/2 particles obey Fermi-Dirac statistics. He gauged his audience perfectly and said, "I’ll prepare a freshman lecture on it." But a few days later he returned and said, "You know, I couldn’t do it. I couldn’t reduce it to the freshman level. That means we really don’t understand it."
            <br/>- Richard P. Feynman, <em>Six Easy Pieces</em></blockquote>
            <h3>Be Nearsighted (and Farsighted)</h3>
            <p>I aim to notice the little things and the big ones; the quarks and the quasars. My definition of greatness involves sweating the small stuff, of manipulating the minutiae and carefully crafting the smallest moving gears in the world around me. These are important; they’re the things that can set me apart, the things that make me a hair taller than the next person in line. But Bill Walsh, legendary 49’ers coach, warns against sweating the <em>wrong</em> small stuff — the trivial, the meaningless. And while the atoms of the day-to-day are vital and require intense focus, it’s important to breathe; to step back, to look around, and to look up at the stars in wonder. To look at the 5-minute view and the 50-year view — to live in the world I exist in while working for the world I am going to build.</p>
            <blockquote>“Every one of us is, in the cosmic perspective, precious. If a human disagrees with you, let him live. In a hundred billion galaxies, you will not find another.”
            <br/>- Carl Sagan, <em>Cosmos</em></blockquote>
            <h3>Be Now, Do Now</h3>
            <p>I’ve talked about the importance of the present moment for a long time. The concept of presence appears in every self-help book and every religious text. The idea that the past and future are human-made concepts isn’t a new one, but it’s painfully true. The present moment is all I have; it’s a place where there is no pain. It’s the <em>flow state</em>, the <em>zone</em>, the place where I do my best work and the place where all my dreams will come true. In some ways, the present moment is forever and eternal — I will always be in the present. Yet in other ways, the present moment is fleeting and ephemeral; it escapes immediately. </p>
            <p>I deeply value being present. But in 2022, I’m realizing that just <em>being</em> isn’t enough. The thing that separates an “eternal present” from a “fleeting present” is not just recognizing the present, but <em>living</em> in it. It’s about acting immediately — it’s about never anticipating a second chance. It’s about leaping out of bed the second my alarm goes off, and scheduling a time to respond to my Slack messages every time I receive them.  It’s about discipline and intense, unrelenting focus. It’s about atomic habits, and (at least 7) effective ones. Sam said it best, when I was complaining to him one day about how I could never wake up when my alarm went off. His response: <em>“If it’s actually important to you, you’ll do it.”</em> I think about that every morning.</p>
            <blockquote>"It is not uncommon for people to spend their whole life waiting to start living."<br/>- Eckhart Tolle, <em>The Power of Now</em></blockquote>
            <h2>Conclusion</h2>
            <p>I’m not going to write about goals here, or lay down my resolutions. The Gregorian calendar has no impact on my DNA or my brain chemistry. I’ll leave the actual systems I put in place, the goals I set, and milestones I hit up to a not-so-distant future version of me, who will tackle each of those things exactly when appropriate. This is my year in review — the year ahead will be characterized by these values, these books, and experiences which I’m excited to encounter. 2020 was 10 years of lessons in 1 year; 2021 was 10 years of lessons in 1 year. The year ahead will bring with it another decade of challenges, lessons, memories, and triumphs. Thank you to everyone who’s made me who I am today — too many to name — you know who you are. I’ve never been more excited to wake up at 8 AM. Let’s rage.</p>
       </div>
    )
}