import { Link } from "react-router-dom";
import styles from "../screens.module.css";

export default function ReflectionScreen2022(props) {
    return(
        <div className={styles.container}>
            <p className={styles.title}>
                Posts
            </p>
            <p className={styles.articleTitle}>
                A Year in Review -- 2022
            </p>
            <p className={styles.articleSubtitle}>
                2023-01-11
            </p>
            <p>In March of this year, I took a road trip with my parents through the heart of the American West, starting from Irvine, California and ending in Omaha, Nebraska. My speedometer — thank God for cruise control — hovered at 95 through 1,600 miles of open roads and starry skies. Along the way, I scrambled through the trails in Zion, Bryce Canyon, and Arches, trekking through the dust as the last memories of the winter winds blew in from the Rockies and down through the Colorado Plateau.</p>
            <p>When my dad came to the US for the first time back in 1986, he touched down at Omaha’s Eppley Airfield and was greeted by his aunt and uncle — <em>mami</em> and <em>mama</em> — professors at a local university. Their support is a lot of the reason he was able to accomplish what he did — a lot of the reason I’ve had the life I’ve had. Growing up, we’d visit the midwest to see them and other family every other Christmas. But 40 years is a long time; time demands its due, and each visit is a painful remembrance that we may only have a single-digit number of those vacations left. And this weighed heavy on me for all those 1,600 miles, as my own mom and dad struggled with their own bodies, their age showing more in the last year than in my previous 22.</p>
            <p>This has been a weird year for me. I’ve been writing year-in-reviews for several years now. Someone told me once that you can gauge your own growth by how much naïveté you recognize in the past versions of yourself; this year is no different. Last year’s essay waxed poetic, offering a romantically optimistic vision for the present and future, perfectly aligned with a dramatic year of internal and external change. I filled it with inspirational quotes and references — my mindset came through in my writing. But this year has been a confusing, winding ball of yarn. To some degree, this essay reflects that.</p>
            <h2>The Laundry List</h2>
            <p>No year in review is complete without its laundry list, so here’s mine. 2022 was the year where I marked the first anniversary of my college graduation, the second anniversary of the death of my first “startup,” my third anniversary with my incredible partner. I finished some hard projects at work, published my first <a href="https://pypi.org/project/postgres-csv-uploader/">Python library</a>, got a 3-D printer, got promoted, took some notes, worked late nights and built a lot of things. I toyed with graphics algorithms, computer networking, optimization problems, low-level matrix operations, distributed systems, language models, databases, mathematics, and more. For the first time, I feel a real confidence in my ability to create; I believe that I can solve any technical problem if you give me enough time.</p>
            <p>I started to love running, ate way more junk food than I should have, started learning the guitar and grew out my hair; my most listened-to genre right now is “neo-psychedelic rock,” whatever that means (maybe I’m just turning into the Dude — I’m terrible at bowling though). I started the year by losing 10 pounds, and ended the year gaining 20. In January I was working from home; in April the 9am train to the office, then 9:30, 10, and 10:30. I won a 6-month HBR subscription from a company hackathon, got my sister’s car, and rekindled some strong relationships with old friends. I grew closer to my sister, my parents, my cousins, and the rest of my family, closer than I’ve ever felt before. I built a 3-D virtual world from scratch alongside my two best friends (one of whom I live with, the other I work with), then flipped the script to plan and host super bowl and Halloween parties with both of them. I completed that half-country road trip, caught COVID at a 5-day-long wedding celebration, and got to experience Phantom of the Opera on Broadway. I played ground shuffleboard in New York; table shuffleboard in Nebraska.</p>
            <h2>Learning</h2>
            <p>This year I read 18 long-form books and countless essays, blog posts, and Wikipedia pages. A couple of my favorites are here, if you’re interested:</p>
            <ul>
                <li><em>The Score Takes Care of Itself</em> by Bill Walsh</li>
                <li><em>Caste</em> by Isabel Wilkerson</li>
                <li><em>Dune</em> by Frank Herbert</li>
                <li><em>The Information</em> by James Gleick</li>
                <li><em>The Innovator's Dilemma</em> by Clayton Christenson</li>
                <li><em>The Man who Knew Infinity</em> by Robert Kanigel</li>
            </ul>
            <p>I spent the first half of this year thinking about how to learn. Never mind my ambitions in last year’s writeup, where I committed to powering through a library of books and an entire curriculum of self-study coursework (I effectively threw all that out the window by March). Instead, I attempted to unravel a more fundamental question. Up until graduation, schools taught me subjects in discrete, serial packages; semester-long surveys of entire fields that attempt to summarize the most critical bits of conceptual knowledge. But it’s difficult to actualize that in industry, where the focus is more on execution than information. The best engineers I know instead advised me to learn <em>pointwise</em> — a blog post here, a Wikipedia article there, maybe 2 pages of a textbook if absolutely necessary.</p>
            <p>There’s some kind of dissonance there, though it’s hard to put into words just how frustrating this dichotomy was at the time. I really do love the textbooks and the survey-courses — the feeling of <em>completion</em> that comes with them. But after talking to many many folks much smarter than I, I think I have an answer I’m happy with — to begin any venture by learning in the pointwise way — a blog post here, a tutorial series there; and let my natural curiosity guide me towards deeper and fuller explorations. The textbooks aren’t off limits, but maybe they should be reserved only for the most important topics; let the amazing summarization powers of the internet suffice until then.</p>
            <p>So I’ve done away with the study plan and the reading list for now; maybe it’ll come back in the future. I’ve learnt it best not to overthink these things.</p>
            <h2>Lessons</h2>
            <p>I drafted this by writing out a long-form paragraph for each “lesson” I picked over this year, but I realized that I was rambling on, stumbling over words trying to describe feelings that I don’t think I can actually put on paper; and I think bullet points do just fine. Here, in no particular order, are some of the lessons I learned in 2022. </p>
            <ul>
                <li><b>Discipline:</b> Learning to do the hard things, to act intentionally and carefully, and force myself to get on that train to work. To take ownership for the mistakes I make.</li>
                <li><b>Focus:</b> To keep the end in mind. Committing myself to the pursuit of great work — the one goal of mine that hasn’t changed in all these years; and to exclude things that look-like-work but actually aren’t. To put an end to hyperbole, exaggeration and theatrics; to live each day honestly.</li>
                <li><b>Taking Initiative:</b> To start things by myself; to stop waiting for an invitation. To offer to carry the torch, and to discover and capture the opportunities that fly by every second. I’m not the best at this — countless examples where I feel like I haven’t done enough for the people that have faith in me; something to improve on.</li>
                <li><b>Gratitude:</b> I don’t want to stumble into cliché. But I do find each day a bit brighter when I take the time to recognize the other people whose actions make my life what it is. I’m one who fully recognizes what a massive role luck plays in the life I lead — to not take what I've been given for granted.</li>
                <li><b>Speaking up:</b> To acknowledge and act on the thoughts and feelings in my head; to speak up and to do the right thing for me, even if it means having uncomfortable conversations or being proven wrong. More on this ahead.</li>
            </ul>
            <h2>Roadblocks</h2>
            <p>This year has been hard. My mother lost her sister in the early summer. My father lost a brother-in-law in early fall. My mom retired this year; my dad’s considering it. The <em>mama</em> that picked my dad up from the airport those 40 years ago now struggles with a host of health problems; an inevitable decline. Men under 25 have the highest car insurance rates, partially because of the near-universal invincibility complex characteristic of the demographic. If I ever had that complex, it’s gone now; every night as I fall asleep, I’m painfully conscious of my heartbeat; of the fact that it won’t last forever; the fragility of  the future, the low, low probability of success in the things I want to do.</p>
            <p>My mental, physical, emotional health are far from where I’d like them to be. I’ve noticed that I’m more anxious, frustrated, and quiet than I’ve been in a long time. My blood pressure’s higher than usual, and I get constant headaches; the thoughts in my head keep me up at night. It’s hard to try and explain these things, and I’m reluctant to self-diagnose myself. But there’s certainly something off in my head, and I’m not quite sure how to fix it; I’m more <em>content</em> with my life than I could hope for — but I’m not sure if that means that I’m <em>happy</em>.</p>
            <p>I’ve always found it hard to talk about myself. I tend to dismiss my feelings, shy away from difficult conversations. I’m not sure when this started, but everyone around me notices — I’ll usually take criticism silently, be quick to apologize, and place more blame on myself than I probably deserve. I don’t mention when things aren’t going well, and also don’t mention when they are. I try really hard not to make my problems anyone else’s. But I’m realizing now that these are bad habits — that they hurt my relationships with other people, as I become more and more of a caricature and less and less of a real person. That the people closest to me would rather I show real excitement, real fear, real sadness, than to lurk in the background, two-dimensional.</p>
            <p>It’s hard to explain what’s going on in my head. I’m not sure if I need to, but putting this out in writing is a step — I’m sharing this with everyone reading because it’s <em>important</em> to me, to be willing to be vulnerable, to be willing to be human. If I haven’t shown up for you in the past year — I apologize. I’m working on it — on being better for myself so I can be better for others.</p>
            <h2>Values</h2>
            <p>I’ll cap this off as I always do — with what my set of values for the coming year are. In the spirit of “strong convictions held loosely,” these are flexible one-liners that serve to remind me about what my focus is, and how to make decisions both big and small.</p>
            <ol>
                <li>Climb relentlessly — in the pursuit of doing great work, and chasing the dream of making a difference. This is my endgame; to reach for the maximum of what I can do given the incredible luck in my life. To be a great engineer, a great student, a great communicator, a great leader, a great problem-solver, a great friend, a great son. To create something that will outlast me and my memory; to lay down my pebble in the gravel road of human progress.  To ask the hard questions, and do the hard work to find the answer.</li>
                <li>Make decisions right — invest the process, and let the score take care of itself. To invest deeply things I can control and being OK with the things I can’t. To confront, and to accept, the role that randomness plays in my life. To stick to my ethics, to live responsibly, to empathize and understand the second-and-third-order effects that my actions have.</li>
                <li>Wherever you go, there you are — a cliché but a good one. Don’t think too hard, life is over quickly, and most things are only hard in my head. Seize the opportunities around me; but if I miss some, don’t worry too much about it; there are more ahead. To feel everything, and dream big — but to not lose sight of what’s right in front of me.</li>
                <li>Keep smiling — no one ever gives you an opportunity to be happy. So do the little things that make moments better; it’s not hard. There is really no such thing as black and white; in most situations there is no need for compromise. A little energy goes a long way.</li>
            </ol>
            <p>This is the short list. Maybe it’ll change over the year — it usually does. My goal is to keep it simple and invest in the things that matter — my relationships, my work, my health. But this helps guide both the micro and the macro, and makes sure that I’m staying honest with myself.</p>
            <h2>End</h2>
            <p>I remember standing by myself in front of Utah’s Courthouse Towers; an enormous, scraggly fin of the Entrada Sandstone posturing brazenly over the desert. Something about the mere act of standing — <em>existing</em> — in front of them was incredibly overwhelming. Not because of the awesome size of the structure, towering cosmically above me, the same as it had over the earliest peoples millennia ago, the same as it will millennia from now. No, not that. I watched a lot of Discovery Channel as a kid. Growing up, when I pictured enormous monuments like these, I could only imagine them as if in a nature documentary. I would picture an aerial helicopter view, accompanied by a crescendo of french horn and trombone, great bells ringing as my imaginary camera swung around their periphery, with some deep-voiced narrator richly coloring the shot. But instead, standing there, the thing that overwhelmed me the most was their tremendous <em>silence</em>, the blanket of absolute, eternal stillness that surrounded them, the pin-drop nothingness punctuated only by my own heartbeat, as they cast their great shadows over me and over the plains, thrown infinitely into the future. </p>
       </div>
    )
}